<template>
  <div>
    <calendarBreadcrumbs :link="'dashboardIcalServer'" />
    <transition name="flixFadeIn">
      <div v-if="$route.params.id !== 'all'" >
        <transition name="flixFadeIn" >
          <div class="flix-form-group" :key="updateKey">
            <h2 class="flix-html-h2">{{ calName }}</h2>
            <h3 class="flix-html-h3">{{ $t('message.icalCalendar', 2) }}</h3>
            <input @click="setFocus" type="text" class="flix-form-control" readonly="true" :value="'https://calserver.bookingflix.com/' + user.user.md5_id + '/' + $route.params.id + '/calendar.ics'">
          </div>
        </transition>
        <transition name="flixFadeIn">
          <div class="flix-form-group" v-if="copied" :key="copied">
            <div class="flix-alert flix-alert-info">{{ $t('message.copy', {name: $tc('message.calendar', 1)}) }}</div>
          </div>
        </transition>

        <h3 class="flix-html-h3">{{ $t('message.needHelp') }}</h3>
        <div class="flix-list-group">
          <a class="flix-list-group-item flix-html-a" href="https://praxistipps.chip.de/ical-in-google-kalender-anzeigen_41504#:~:text=%C3%96ffnen%20Sie%20den%20Google%20Kalender,Sie%20mit%20%22Kalender%20hinzuf%C3%BCgen%22." target="_blank"><flixIcon id="question-sign" /> Google-Kalender</a>
          <a class="flix-list-group-item flix-html-a" href="https://www.ff-altenschwand.de/seiten/service/ical/android.html" target="_blank"><flixIcon id="question-sign" /> Android</a>
          <a class="flix-list-group-item flix-html-a" href="https://www.pocketpc.ch/apple-ios-tutorials/204144-tutorial-einrichtung-ical-kalender-formates-fuer-ios-geraete-ics-kalender.html" target="_blank"><flixIcon id="question-sign" /> iPhone</a>
          <a class="flix-list-group-item flix-html-a" href="https://dottech.org/190699/how-to-add-ical-feed-to-windows-10-calendar-app-tip/" target="_blank"><flixIcon id="question-sign" /> Windows 10</a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') }
  },
  props: {},
  data () {
    return {
      updateKey: false,
      user: this.$getUserVariables(),
      copied: false,
      calName: ''
    }
  },
  watch: {
    '$route.params.id' () { this.getAssistent(); this.getCalendarName() }
  },
  methods: {
    getCalendarName () {
      if (this.$route.params.id === 'all') {
        this.calName = ''
        return false
      }

      var cal = this.$store.getters.assistentsByID
      this.calName = cal[this.$route.params.id].title
    },
    getAssistent () {
      this.updateKey = new Date().getTime()
      return false
    },
    setFocus (event) {
      event.target.select()
      try {
        document.execCommand('copy')
        this.copied = true
        setTimeout(function () {
          this.copied = false
        }.bind(this), 2000)
      } catch (err) {
        alert('Oops, unable to copy')
      }
    }
  },
  mounted () {
    this.getCalendarName()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
